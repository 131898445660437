@import '../../../scss/base.module';

.spottyCatHeader {
  &__interactions {
    display: flex;
    justify-content: space-between;
    margin: $spacing-s $spacing-l;
    padding-top: $spacing-s;
  }

  &__button {
    padding: 0 $spacing-s;
  }
}
