@import '../../scss/base.module';

$iconMargin: $spacing-m;
$autocompleterPadding: $spacing-m;
$autocompleterRadius: $spacing-xxs;

.autocompleter {
  $autocompleter-height: 56px;

  position: relative;

  &--error {
    box-sizing: border-box;
    border: 1px solid $danger;
    border-radius: $autocompleterRadius;
  }

  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    background-color: white;
  }

  &__no-result-button {
    margin-top: $spacing-xs;
    width: 100%;
  }

  &__input-container {
    position: relative;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-radius: $autocompleterRadius;
    min-height: $autocompleter-height;
    padding-left: $spacing-s;
  }

  &__glass {
    margin-right: $spacing-s;
    width: 18px;
  }

  &__input {
    @include font-bold;
    @include font-m;

    @include from-laptop {
      @include font-s;
    }

    border: 0;
    color: $interaction-dark;
    background-color: transparent;
    flex-grow: 1;
    outline: none;
    padding: $autocompleterPadding;
    padding-left: 0;
    max-width: calc(100% - 18px - #{$spacing-s}); // minus margin, minus svg width

    &::placeholder {
      font-size: $font-m;
      font-style: normal;
      color: $interaction-dark;

      @include from-laptop {
        font-size: $font-s;
      }
    }
  }

  &__item-container {
    background-color: white;
    border-radius: 0 0 $autocompleterRadius $autocompleterRadius;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: 2px;
  }

  &__list-wrapper {
    overflow-y: hidden;
    transition: height 0.25s ease;
    margin-top: $spacing-s;

    &--floating {
      position: absolute;
      width: 100%;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    }
  }

  &__item-icon {
    margin-right: $spacing-s;
  }

  &__item {
    min-height: $autocompleter-height;
    padding: 10px $spacing-xs 0;
    box-sizing: border-box;

    &-inner {
      @include font-s;

      color: $interaction-dark;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding-bottom: 11px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;

    }

    &-match {
      @include font-bold;
      @include font-s;

      color: $interaction-dark;
    }

    &--no-result {
      padding: $spacing-m;

      span {
        white-space: pre-wrap;
        padding-bottom: 0;
      }
    }

    &--preselected {
      background-color: $primary-light;
    }

    &:not(:last-child) &-inner {
      border-bottom: 1px solid rgba(0, 48, 87, 0.07);
    }

    &:hover:not(.autocompleter__item--no-result) {
      cursor: pointer;
      background-color: $tertiary;
      color: $interaction-dark;

      & span {
        color: inherit;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
