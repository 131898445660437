@import '../../scss/base.module';

.form-radio-button {
  &:hover:enabled {
    border-color: $interaction-dark;
  }

  &--hideDot {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &__icon {
    width: 36px;
    margin-right: $spacing-s;
    flex-shrink: 0;

    & > svg,
    img {
      width: 100%;
    }

    &--colored {
      color: $interaction;

      svg {
        fill: currentColor;
      }
    }
  }

  &__input {
    height: 20px;
    opacity: 0;
    position: absolute;
    width: 20px;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
    }
  }

  &__circle {
    $circle-size: 18px;

    border-radius: 50%;
    border: solid 1px $light;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: $circle-size;
    width: $circle-size;
    margin-right: $spacing-xs;
    box-sizing: border-box;

    &--right {
      order: 2;
      margin-right: 0;
      margin-left: $spacing-s;
    }

    &--selected {
      border: solid 1.5px $interaction;

      &:after {
        content: '';
        border-radius: 50%;
        height: 8px;
        width: 8px;
        background-color: $interaction;
      }
    }

    &--disabled {
      cursor: not-allowed;
      border: solid 1px $interaction-dark;
    }
  }

  &__label {
    @include font-light;
    @include font-m;

    background-color: var(--sah-input-background);
    border-radius: 4px;
    border: solid 1px $light;
    box-shadow: 0 2px 6px 0 $shadow;
    display: flex;
    color: $interaction-dark;
    align-items: center;
    word-break: break-all;
    padding: $spacing-m;
    cursor: pointer;

    &--disabled {
      opacity: 0.3;
      cursor: not-allowed;
      background-color: $light;
      border-color: $light;

      &:hover {
        border-color: $light;
      }
    }

    &:hover:not(.form-radio-button__label--selected):not(.form-radio-button__label--disabled) {
      border-color: $interaction-dark;
    }

    &--horizontal {
      justify-content: center;
    }

    &--selected {
      @include font-bold;

      border: solid 1.5px $interaction;
      color: $interaction;
      background-color: $white;
    }

    &--blinking {
      animation-name: blinking;
      animation-duration: var(--ui-option-delay);
    }

    &--error {
      border: solid 1.5px $danger;
    }

    &--noBorder {
      border: 0;
      box-shadow: unset;
    }
  }
}

@keyframes blinking {
  0% {
    border-color: $interaction;
  }

  25% {
    border-color: transparent;
  }

  50% {
    border-color: $interaction;
  }

  75% {
    border-color: transparent;
  }

  100% {
    border-color: $interaction;
  }
}
