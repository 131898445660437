@import '../../../scss/base.module';

.spotty-cat-intro {
  width: 240px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &__title {
    @include font-bold;

    margin-top: $spacing-l;
  }
}
