@import '../../../scss/base.module';

.spotty-cat-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: auto;
  max-height: 100% !important; // override Modal.scss styles
  margin: 0 !important;
  border-radius: 0;

  @include from-tablet {
    width: 680px !important;
    height: auto;
    border-radius: 16px !important;
  }

  &__screen {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 360px;

    @include from-laptop {
      margin: 48px auto 0;
      max-width: 540px;
      min-height: 480px;
    }
  }
}
