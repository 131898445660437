@import '../../../scss/base.module';

.spottyCatBar {
  height: 56px;
  background-color: $secondary-pink;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 1s ease-in-out;
  position: fixed;
  bottom: 0;
  left: 0;

  @include from-tablet {
    position: initial;
  }

  &__progress {
    @include font-bold;
    @include font-l;

    color: white;
  }
}
